<template>
  <div class="add-client-wrapper"
       v-show="isShow">
    <div class="add-client-popup">
      <div class="header">
        <span>添加客户</span>
        <i class="el-icon-close btn-close"
           @click="closePopup"></i>
      </div>
      <div class="body">
        <div class="left-container">
          <div class="search-container">
            <el-input placeholder="请搜索客户"
                      v-model="searchName"
                      @keyup.enter.native="search">
              <el-button slot="append"
                         icon="el-icon-search"
                         @click="search"></el-button>
            </el-input>
          </div>
          <el-table :data="clientList"
                    ref="clientTable"
                    style="width:100%;"
                    @row-click="clickRow"
                    @select="checkRow"
                    @select-all="selectAll">
            <el-table-column type="selection"
                             width="55">
            </el-table-column>
            <el-table-column label="姓名"
                             width="180">
              <template slot-scope="scope">
                <div class="flex-v-center">
                  <img :src="scope.row.avatar"
                       width="36px"
                       height="36px"
                       style="border-radius:50%">
                  <span class="ml10">{{ scope.row.name }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="跟进人"
                             width="150px"
                             align="center">
              <template slot-scope="scope">
                <span style="text-align:center;">{{ scope.row.follow_name }}</span>
              </template>
            </el-table-column>
            <el-table-column label="部门"
                             width="150px"
                             align="center">
              <template slot-scope="scope">
                <span style="text-align:center;">{{ scope.row.department }}</span>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-container">
            <el-pagination background
                           layout="prev, pager, next"
                           :page-size="pageSize"
                           :current-page.sync="currentPage"
                           :total="totalClient"
                           :pager-count="5"
                           @current-change="changePage">
            </el-pagination>
          </div>
        </div>
        <div class="right-container">
          <div class="tips">已选择{{selectClientList.length}}个客户(最多可添加300个客户)</div>
          <div class="select-client-container">
            <div class="tips ab-center text-center"
                 v-if="selectClientList.length === 0">请在左侧选择你要添加的客户</div>
            <div v-else>
              <div v-for="(client,index) in selectClientList"
                   :key="client.id"
                   class="client-item">
                <div class="flex-v-center">
                  <el-avatar size="medium"
                             :src="client.avatar"></el-avatar>
                  <span class="ml10">{{client.name}}</span>
                </div>
                <i class="el-icon-close btn-close-small"
                   @click="deleteClient(client,index)"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <span class="btn btn-cancel"
              @click="closePopup">取消</span>
        <span class="btn btn-confirm ml10"
              @click="confirm">确定</span>
      </div>
    </div>
  </div>
</template>

<script>
import { reqClientList } from '@/api'
export default {
  name: 'AddClientPopup',
  props: {
    isShow: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      searchName: '',
      currentPage: 1,
      pageSize: 6,
      totalClient: 0,
      selectClientList: [],
      clientList: [],
    }
  },
  created () {
    // this.init()
  },
  methods: {
    init () {
      let params = {
        page: this.currentPage,
        limit: this.pageSize,
      }
      if (this.searchName) params.keyword = this.searchName
      this.initData(params)
    },
    initData (params) {
      reqClientList(params).then(({ data }) => {
        this.totalClient = data.costomerCount
        this.clientList = data.costomerList
        this.$nextTick(() => {
          if (this.selectClientList.length === 0) return
          this.clientList.forEach(client => {
            let obj = this.selectClientList.find(item => item.id === client.id)
            if (obj) this.$refs.clientTable.toggleRowSelection(client, true)
          })
        })
      })
    },
    search () {
      this.currentPage = 1
      this.init()
    },
    confirm () {
      this.$emit('update:isShow', false)
      this.$emit('select', this.selectClientList)
    },
    closePopup () {
      this.$emit('update:isShow', false)
      // this.$emit('close')
    },
    clickRow (row) {
      this.$refs.clientTable.toggleRowSelection(row, true)
      this.handleSelectClient(row)
    },
    checkRow (selection, row) {
      this.handleSelectClient(row)
    },
    handleSelectClient (row) {
      if (this.selectClientList.length <= 300) {
        let index = this.selectClientList.findIndex(item => item.id === row.id)
        if (index === -1) {
          this.selectClientList.push(row)
        } else {
          this.selectClientList.splice(index, 1)
          this.$refs.clientTable.toggleRowSelection(row, false)
        }

      } else {
        this.$message.warning('最多可添加300个客户')
        this.$refs.clientTable.toggleRowSelection(selection.pop(), false)
      }
    },
    selectAll (selection) {
      if (selection.length === 0) {

        this.clientList.forEach(item => {
          let index = this.selectClientList.findIndex(client => client.id === item.id)
          if (index !== -1)
            this.selectClientList.splice(index, 1)
        })
      } else {
        selection.forEach(item => {
          let index = this.selectClientList.findIndex(client => client.id === item.id)
          if (index === -1)
            this.selectClientList.push(item)
        })
      }
    },
    changePage (page) {
      this.currentPage = page
      this.init()
    },
    deleteClient (client, index) {
      this.selectClientList.splice(index, 1)
      this.$refs.clientTable.toggleRowSelection(client, false)
    }
  },
  watch: {
    isShow (val) {
      if (val) this.init()
    }
  }
}
</script>

<style scoped>
.add-client-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000070;
  z-index: 1003;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-client-popup {
  min-width: 800px;
  min-height: 700px;
  box-sizing: border-box;
  padding: 15px 20px;
  background: #ffffff;
  border-radius: 8px;
  z-index: 1004;
  position: relative;
  padding-bottom: 70px;
}
.add-client-popup .header {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-close {
  font-size: 26px;
  cursor: pointer;
  margin-right: 10px;
}
.btn-close-small {
  font-size: 20px;
  cursor: pointer;
}
.body {
  width: 100%;
  min-height: 550px;
  margin-top: 20px;
  display: flex;
}
.left-container {
  flex: 1;
  height: 100%;
  border-right: 1px solid #e9e9e9;
}
.right-container {
  width: 380px;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;
}
.search-container {
  width: 100%;
  height: 50px;
  padding: 5px 20px;
  box-sizing: border-box;
}
.flex-v-center {
  display: flex;
  align-items: center;
}
.pagination-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
  margin-top: 15px;
  box-sizing: border-box;
  z-index: 10;
}

.footer {
  width: 100%;
  height: 60px;
  border-top: 1px solid #e9e9e9;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  box-sizing: border-box;
}
.tips {
  width: 100%;
  height: 50px;
  padding: 5px 0;
  box-sizing: border-box;
  line-height: 40px;
  font-size: 14px;
  color: #999999;
}
.select-client-container {
  width: 100%;
  height: 500px;
  background: #f5f5f7;
  border-radius: 4px;
  overflow-y: auto;
  padding: 10px 20px;
  box-sizing: border-box;
}

.ab-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.text-center {
  text-align: center;
}
.client-item {
  width: 100%;
  height: 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-radius: 4px;
  padding: 5px 15px;
  box-sizing: border-box;
}
.client-item:nth-child(n + 2) {
  margin-top: 10px;
}
</style>