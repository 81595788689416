<template>
  <div v-show="isShow">
    <div class="add-task-wrapper">
      <div class="add-task-popup">
        <div class="header">
          <span>下发任务：{{taskType}}</span>
          <i class="el-icon-close btn-close"
             @click="closePopup"></i>
        </div>

        <div class="body">
          <el-form :model="task"
                   :rules="rules"
                   ref="taskForm"
                   label-position="left"
                   label-width="16%">
            <el-form-item label="任务标题"
                          prop="title">
              <el-input placeholder="请输入任务标题"
                        v-model="task.title"
                        clearable>
              </el-input>
            </el-form-item>
            <el-form-item label="任务说明"
                          prop="content">
              <el-input type="textarea"
                        v-model="task.content"
                        maxlength="500"
                        :rows="5"
                        placeholder="请输入任务说明"
                        show-word-limit>
              </el-input>
            </el-form-item>
            <el-form-item label="时间"
                          prop="datetime">
              <el-date-picker v-model="task.datetime"
                              :picker-options="pickerOptions"
                              type="datetimerange"
                              range-separator="-"
                              value-format="timestamp"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期">
                <!-- @change="changeDatetime" -->
              </el-date-picker>
            </el-form-item>
            <el-form-item label="客户"
                          :prop="pointType">
              <el-tabs v-model="pointType"
                       type="card"
                       @tab-click="changePointType">
                <el-tab-pane label="不指定"
                             name="nopoint"></el-tab-pane>
                <el-tab-pane label="指定客户"
                             name="clientName">
                  <div class="client-list">
                    <span class="btn-add-client"
                          @click="showAddClientPopup">+添加客户</span>
                    <el-tag v-for="(tag,index) in task.client"
                            :key="tag.name+index"
                            closable
                            class="tag-client"
                            type="info"
                            @close="deleteClient(tag)">
                      {{tag.name}}
                    </el-tag>
                  </div>
                </el-tab-pane>
              </el-tabs>
              <el-input v-show="false"
                        v-model="task.clientName"></el-input>
            </el-form-item>
            <el-form-item label="处理人"
                          prop="proveName">
              <div class="prove-box"
                   v-show="pointType === 'nopoint'"
                   @click="showProvePopup"
                   key="nopoint">
                <span v-if="task.proveName === ''">请选择</span>
                <span v-else
                      style="color:#000000">{{task.proveName}}</span>
              </div>
              <div class="prove-type"
                   v-show="pointType === 'clientName'">
                <el-radio v-model="task.proveType"
                          :label="1"
                          size="medium">
                  <span>由客户跟进人处理，无跟进人由</span>
                  <span class="prove-select-box"
                        @click="showProvePopup">
                    <span v-if="task.proveName === ''|| task.proveType !== 1">请选择</span>
                    <span v-else
                          style="color:#000000">{{task.proveName}}</span>
                  </span>
                  <span>处理</span>
                </el-radio>
                <el-radio v-model="task.proveType"
                          :label="2"
                          size="medium">
                  <span>统一由</span>
                  <span class="prove-select-box"
                        @click="showProvePopup">
                    <span v-if="task.proveName === '' || task.proveType !== 2">请选择</span>
                    <span v-else
                          style="color:#000000">{{task.proveName}}</span>
                  </span>
                  <span>处理</span>
                </el-radio>
              </div>
              <el-input v-show="false"
                        v-model="task.proveName"></el-input>
            </el-form-item>
          </el-form>
        </div>

        <div class="footer">
          <span class="btn btn-cancel"
                @click="closePopup">取消</span>
          <span class="btn btn-confirm ml10"
                @click="confirm">确定</span>
        </div>
      </div>
    </div>
    <transition name="el-fade-in">
      <Add-client-popup :isShow.sync="isShowAddClientPopup"
                        @select="selectClientList" />
    </transition>

    <transition name="el-fade-in">
      <div class="prove-list-wrapper"
           v-show="isShowProvePopup"
           @click="closeProvePopup">
        <div class="prove-list-container"
             @click.stop="stopPropagation">
          <el-input placeholder="请搜索处理人"
                    v-model="searchProveName">
          </el-input>
          <el-tree class="mt15 prove-tree"
                   accordion
                   ref="proveTree"
                   :data="staffList"
                   :props="defaultPropsProveList"
                   :filter-node-method="filterProveList"
                   @node-click="selectProve">
            <span class="prove-tree-node"
                  slot-scope="{ data }">
              <el-avatar v-if="data.avatar"
                         class="mr10"
                         size="small"
                         :src="data.avatar"></el-avatar>
              <span>{{ data.name }}</span>
            </span>
          </el-tree>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { reqAddTask, reqStaffList } from '@/api'
import AddClientPopup from '@/components/Task/AddClientPopup'
export default {
  name: 'AddTaskPopup',
  components: { AddClientPopup },
  props: {
    taskType: {
      type: String,
      required: true
    },
    isShow: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      isShowAddClientPopup: false,
      isShowProvePopup: false,
      task: {
        title: '',
        content: '',
        datetime: [],
        client: [

        ],
        clientName: '',
        proveType: 1,
        proveName: '',
      },
      rules: {
        title: [
          { required: true, message: '请输入任务标题', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入任务说明', trigger: 'blur' }
        ],
        datetime: [
          { required: true, message: '请选择任务时间', trigger: 'blur' }
        ],
        clientName: [
          { required: true, message: '请指定客户', trigger: 'change' }
        ],
        nopoint: [
          { required: false }
        ],
        proveName: [
          { required: true, message: '请选择处理人', trigger: 'change' }
        ]
      },
      pickerOptions: {
        disabledDate: (date) => {
          return date.getFullYear() < this.today.getFullYear()
            || date.getMonth() < this.today.getMonth()
            || (date.getMonth() === this.today.getMonth() && date.getDate() < this.today.getDate())
        }
      },
      pointType: 'nopoint',
      searchProveName: '',
      defaultPropsProveList: {
        children: 'children',
        label: 'name'
      },
      proveid: '',
      staffList: []
    }
  },
  computed: {
    today () {
      return new Date()
    }
  },
  created () {
    this.setDefaultTaskTime()
    // this.getStaffList()
  },
  methods: {
    stopPropagation () { },
    getStaffList () {
      if (this.staffList.length === 0 && this.isShow) {
        reqStaffList().then(res => {
          this.staffList = res.data
          this.handleStaffList(this.staffList)
        })
      }
    },
    handleStaffList (data) {
      data.forEach(item => {
        if (item.children) {
          this.handleStaffList(item.children)
        }
        if (item.member) {
          item.children = item.children ? item.member.concat(item.children) : item.member
        }
      })
    },
    filterProveList (value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1
    },
    closePopup () {
      this.$emit('update:isShow', false)
    },
    setDefaultTaskTime () {
      let now = new Date()
      this.task.datetime = [now.getTime() + 15 * 60 * 1000, now.getTime() + 45 * 60 * 1000]
    },
    // changeDatetime (date) {

    //   this.task.datetime = date
    // },
    deleteClient (tag) {
      this.task.client.splice(this.task.client.indexOf(tag), 1)
      this.task.clientName = this.task.client.length > 0 ? this.task.client.map(({ name }) => name).join() : ''
    },
    changePointType ({ name }) {
      this.pointType = name
      this.$refs.taskForm.clearValidate()
    },
    showAddClientPopup () {
      this.isShowAddClientPopup = true
    },
    confirm () {

      this.$refs.taskForm.validate((valid) => {
        if (!valid) return false

        let [startTime, endTime] = this.task.datetime
        if (startTime < new Date().getTime()) {
          this.$message.warning('任务时间要大于当前时间')
          return false
        }

        let params = {
          title: this.task.title,
          content: this.task.content,
          create_id: '',
          create_name: '',
          start_time: Math.floor(startTime / 1000),
          end_time: Math.floor(endTime / 1000),
          prove_id: this.proveid,
        }

        params.create_id = localStorage.getItem('phone')
        params.create_name = localStorage.getItem('user_name')
        if (this.pointType === 'clientName') {
          params.customerids = this.task.client.map(({ id }) => id).join()
          params.prove_type = this.task.proveType
        } else {
          params.prove_type = 2
          //当不指定客户时，默认客户为Tony
          params.customerids = 'wmY14uCQAAHiEvDV5ZVyyDVg7w4dRdgA'
        }
        reqAddTask(params).then(() => {
          // this.$emit('update:isShow', false)
          this.$emit('addSuccess')
          this.$message.success('任务下发成功')
        })
      })
    },
    selectClientList (clientList) {
      this.task.client = clientList
      this.task.clientName = clientList.map(({ name }) => name).join()
    },
    showProvePopup () {
      this.isShowProvePopup = true
    },
    selectProve (val) {
      if (val.children) return
      this.task.proveName = val.name
      this.proveid = val.mobile
      this.isShowProvePopup = false
    },
    closeProvePopup () {
      this.isShowProvePopup = false
    }
  },
  watch: {
    searchProveName (val) {
      this.$refs.proveTree.filter(val);
    },
    isShow () {
      this.getStaffList()
    }
  }
}
</script>

<style scoped>
.add-task-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000070;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-task-popup {
  width: 800px;
  min-height: 550px;
  box-sizing: border-box;
  padding: 15px;
  background: #ffffff;
  border-radius: 8px;
  z-index: 1001;
  position: relative;
}

.add-task-popup .header {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-close {
  font-size: 26px;
  cursor: pointer;
  margin-right: 10px;
}
.add-task-popup .body {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 60px;
}
.add-task-popup .body >>> .el-textarea__inner:focus {
  border-color: #5757d9;
}
.add-task-popup .body >>> .el-form-item {
  margin-bottom: 20px !important;
}
.add-task-popup .body >>> .el-range-editor.is-active,
.el-range-editor.is-active:hover {
  border-color: #5757d9;
}

.add-task-popup .body >>> .el-tabs--card > .el-tabs__header {
  border-bottom: none;
}
.add-task-popup .body >>> .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border-bottom: 1px solid #e4e7ed;
}

.add-task-popup .body >>> .el-tabs__header {
  margin: 0;
}

.add-task-popup .body >>> .el-form-item__content {
  line-height: 30px;
}
.add-task-popup .body >>> .el-tabs__item {
  height: 30px;
  line-height: 30px;
  border: 1px solid transparent;
}

.add-task-popup .body >>> .el-tabs__item:hover {
  border-color: #5757d9;
}
.add-task-popup .body >>> .el-tabs__item.is-active {
  border-color: #5757d9;
}

.client-list {
  width: 100%;
  min-height: 34px;
  max-height: 70px;
  line-height: 34px;
  margin-top: 10px;
  overflow-y: auto;
}
.btn-add-client {
  display: inline-block;
  border: 1px dashed #e1e3e6;
  padding: 0 10px;
  cursor: pointer;
}
.tag-client:nth-child(n + 2) {
  margin-left: 10px;
}

.prove-box {
  color: #00000050;
  text-indent: 15px;
  width: 100%;
  border: 1px solid #e1e3e6;
}

.prove-box:hover,
.prove-box:focus {
  border-color: #5757d9;
}

.prove-select-box {
  min-width: 120px;
  display: inline-block;
  border: 1px solid #e1e3e6;
  text-indent: 5px;
  color: #00000050;
  padding: 6px 15px;
  margin: 0 10px;
  box-sizing: border-box;
}
.prove-select-box:hover,
.prove-select-box:focus {
  border-color: #5757d9;
}
.prove-type {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.prove-type >>> .el-radio__input.is-checked + .el-radio__label {
  color: #000000;
}

.prove-type >>> .el-radio:first-child {
  margin-bottom: 10px;
}

.footer {
  width: 100%;
  height: 60px;
  border-top: 1px solid #e9e9e9;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  box-sizing: border-box;
}

.prove-list-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000070;
  z-index: 1002;
  display: flex;
  justify-content: center;
  align-items: center;
}
.prove-list-container {
  width: 500px;
  height: 400px;
  /* overflow-y: auto; */
  position: absolute;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1003;
  padding: 10px 20px;
}
.mt15 {
  margin-top: 15px;
}
.mr10 {
  margin-right: 10px;
}

.prove-tree {
  height: calc(100% - 50px);
  overflow-y: auto;
}

.prove-tree-node {
  display: flex;
  align-items: center;
}
.prove-tree >>> .el-tree-node__content {
  height: 40px;
}
</style>