<template>
  <div v-loading="isLoading"
       element-loading-text="拼命加载中"
       element-loading-spinner="el-icon-loading"
       class="task">
    <div class="task">
      <div class="title">{{ $route.meta.title }}</div>

      <div class="container">
        <div class="left-nav">
          <div class="add-task"
               v-if="isTaskPublish">
            <!-- <el-popover placement="bottom-start"
                        trigger="hover"
                        :offset="5"
                        v-model="isShowAddTaskOptions">
              <div v-for="(item,index) in addTaskOptions"
                   :key="index">
                <div>{{item.title}}</div>
                <div class="mt10">
                  <div v-for="(option,index) in item.options"
                       :key="index"
                       @click="showAddTaskPopup(option.name)"
                       class="option">
                    <img :src="option.img" />
                    <div class="mt10">{{option.name}}</div>
                  </div>
                </div>
              </div>
              <el-button class="btn-add-task"
                         slot="reference">下发任务</el-button>
            </el-popover> -->
            <el-button class="btn-add-task"
                       @click="showAddTaskPopup('通用类型')">下发任务</el-button>
          </div>
          <el-menu class="mt20"
                   :default-active="menuList[0].name">
            <el-menu-item v-for="(item,index) in menuList"
                          @click="changeTaskStatus(item.value,index)"
                          :key="item.name"
                          :index="item.name"
                          :style="{textAlign:item.title.includes('任务') ? 'left' :'center'}">
              {{item.title}}({{item.num}})
            </el-menu-item>
          </el-menu>
        </div>
        <div class="right-content">
          <el-select v-model="taskType"
                     class="select-task-type"
                     placeholder="请选择"
                     @change="changeTaskType">
            <el-option v-for="item in taskTypeOptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>

          <div class="task_container"
               v-if="taskList && taskList.length > 0"
               v-infinite-scroll="loadMoreData"
               :infinite-scroll-disabled="isLoadingMore"
               :infinite-scroll-delay="3000"
               :infinite-scroll-distance="100"
               :infinite-scroll-immediate="false">
            <div class="item_task"
                 v-for="(task,index) in taskList"
                 :key="task.id"
                 @click="showTaskDetail(task,index)">
              <div>
                <img :src="task.task_type_img"
                     width="24px"
                     height="24px">
              </div>
              <div class="item_right">
                <div class="font16 line24 flex-between">
                  <span>{{task.task_type_label}}</span>
                  <div :class="['task_detail_status',setTaskStatusColor(task.task_status)]">
                    <span>{{task.task_status_label}}</span>
                    <span>({{handleTaskFinishNum(task.kehu)}}/{{task.kehu && task.kehu.length === 0 ? 1 : task.kehu.length}})</span>
                  </div>

                </div>
                <el-popover placement="top-start"
                            :title="task.title"
                            width="200"
                            trigger="hover">
                  <div style="max-height:300px;overflow-y:auto;padding:10px">{{task.content}}</div>
                  <div class="task-content ellipsis"
                       slot="reference">{{task.content}}</div>
                </el-popover>

                <div class="task-info">
                  <i class="el-icon-time"></i>
                  <span class="mr20">{{task.start_time+' 至 '+task.end_time}}</span>
                  <span v-if="task.customerNameList && task.customerNameList.length > 0"
                        class="flex-v-center">
                    <i class="el-icon-user"></i>
                    <span class="customer_name_box ellipsis">{{task.customerNameList}}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div v-else
               class="empty_container">
            目前还没有任务
          </div>
        </div>
      </div>
      <el-drawer :visible.sync="isShowTaskDetail"
                 :before-close="closeTaskDetail"
                 :show-close="false"
                 size="40%"
                 v-for="task in taskList"
                 :key="task.id">
        <div slot="title"
             class="task-detail-header">
          <div class="flex-v-center">
            <img :src="currentTaskDetail.task_type_img"
                 width="32px"
                 height="32px"
                 class="mr15" />
            <span class="task_detail_title mr15">{{currentTaskDetail.task_type_label}}</span>
            <span :class="['task_detail_status',setTaskStatusColor(currentTaskDetail.task_status)]">{{currentTaskDetail.task_status_label}}({{handleTaskFinishNum(currentTaskDetail.kehu)}}/{{
              currentTaskDetail.kehu && currentTaskDetail.kehu.length > 0 ?  currentTaskDetail.kehu.length : 1}})</span>
          </div>
          <div>
            <!-- <span class="btn-task-detail" @click="showAddTaskPopup(currentTaskDetail.task_type_label,'edit')">编辑</span> -->
            <span v-if="!isTaskPublish && !isFinshTask"
                  class="btn-task-detail"
                  @click="finishTask()">完成任务</span>
            <span v-if="isTaskPublish"
                  class="btn-task-detail"
                  @click="showDeleteTaskPopup(currentTaskDetail.id,currentTaskDetail.task_status)">删除</span>
          </div>
        </div>

        <div class="task_detail_info">
          <div class="task_detail_info_line">
            <span>任务说明：</span>
            <span>{{currentTaskDetail.content}}</span>
          </div>
          <div class="task_detail_info_line">
            <span>时间：</span>
            <span class="mr20">{{task.start_time+' 至 '+task.end_time}}</span>
          </div>
        </div>

        <div class="task_detail_content">
          <el-tabs type="card"
                   v-model="taskDetailActiveName">
            <el-tab-pane label="处理人维度"
                         v-if="isTaskPublish"
                         name="prove">
              <el-table :data="currentTaskDetail.proveList"
                        style="width: 100%">
                <el-table-column prop="proveName"
                                 label="处理人"
                                 width="180">
                </el-table-column>
                <el-table-column prop="process"
                                 label="进度"
                                 width="180">
                  <template slot-scope="scope">
                    <span style="color:#5252cc">{{ scope.row.process }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="isFinish"
                                 label="完成情况">
                  <template slot-scope="scope">
                    <span :class="['task-dot',scope.row.isFinish ? 'bg-green' : 'bg-red']"></span>
                    <span>{{ scope.row.isFinish ? '已完成' : '未完成' }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="finishTime"
                                 label="完成时间">
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="客户维度"
                         name="customer"
                         v-if="currentTaskDetail.kehu && currentTaskDetail.kehu.length > 0">
              <el-table :data="currentTaskDetail.kehu"
                        ref="subtaskTable"
                        style="width: 100%"
                        height="600px"
                        @select-all="selectAllTask"
                        @select="selectTask">
                <el-table-column v-if="!isTaskPublish"
                                 :selectable="checkSelectable"
                                 type="selection"
                                 width="55">
                </el-table-column>
                <el-table-column prop="customer_name"
                                 label="姓名"
                                 width="180">
                </el-table-column>
                <el-table-column prop="prove_name"
                                 label="处理人"
                                 width="180">
                </el-table-column>
                <el-table-column prop="status"
                                 label="完成情况">
                  <template slot-scope="scope">
                    <span :class="['task-dot',scope.row.status === 2 ? 'bg-green' : 'bg-red']"></span>
                    <span>{{ scope.row.status === 2 ? '已完成' : '未完成' }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="finish_time"
                                 label="完成时间">
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-drawer>

      <add-task-popup :isShow.sync="isShowAddTaskPopup"
                      :taskType="currentTaskType"
                      @addSuccess="addTask" />
    </div>
  </div>
</template>

<script>
import { reqTaskList, reqTaskNum, reqDeleteTask, reqFinishTask, reqFinishSubtask, reqTaskDetail } from "@/api/index"
import { timestampToTime } from '@/util/utils'
import AddTaskPopup from '@/components/Task/AddTaskPopup'
export default {
  name: 'TaskCenter',
  components: { AddTaskPopup },
  data () {
    return {
      isLoading: false,
      isLoadingMore: false,
      isShowAddTaskOptions: false,
      isShowTaskDetail: false,
      isShowAddTaskPopup: false,
      pageNum: 0,

      addTaskOptions: [
        {
          title: '跟进客户任务',
          options: [
            {
              name: '通用类型',
              value: 1,
              img: require('@/assets/images/other.png')
            }
          ]
        }
      ],
      taskStatusList: ['进行中', '已完成', '已逾期', '逾期完成', '待开始'],
      menuList: [
        {
          name: 'pengingTask',
          title: '待处理任务',
          value: '1,3,5',
          num: 0,
        },
        {
          name: 'tasking',
          title: '进行中',
          value: '1',
          num: 0,
        },
        {
          name: 'notStartTask',
          title: '未开始',
          value: '5',
          num: 0
        },
        {
          name: 'overdueTask',
          title: '已逾期',
          value: '3',
          num: 0
        },

        {
          name: 'historyTask',
          title: '历史任务',
          value: '2,4',
          num: 0,
        },
        {
          name: 'onTimeFinishTask',
          title: '按时完成',
          value: '2',
          num: 0,
        },
        {
          name: 'lateFinishTask',
          title: '逾期完成',
          value: '4',
          num: 0,
        }
      ],
      taskTypeOptions: [
        {
          label: '全部类型',
          value: 0
        },
        {
          label: '通用类型',
          value: 1,
          img: require('@/assets/images/other.png')
        },
      ],
      taskType: 0,
      taskStatus: '1,3,5',
      currentMenuIndex: 0,
      currentTaskDetail: {},
      currentTaskDetailIndex: 0,
      currentTaskType: '',
      taskList: [],
      selectTaskList: [], //选择将要完成的子任务列表
      phone: '',
    }
  },
  computed: {
    reqKey () {
      return this.isTaskPublish ? 'create_id' : 'prove_id'
    },
    reqParams () {
      let params = {}
      if (this.taskType !== 0) params.task_type = this.taskType
      params.task_status = this.taskStatus
      params[this.reqKey] = this.phone
      params.number = this.phone
      params.number_type = this.isTaskPublish ? 1 : 2
      params.page_num = this.pageNum
      return params
    },
    isTaskPublish () {
      return this.$route.name === 'TaskPublish'
    },
    isFinshTask () {
      return this.taskStatus === '2,4' || this.taskStatus === '2' || this.taskStatus === '4'
    },
    taskDetailActiveName () {
      return this.isTaskPublish ? 'prove' : 'customer'
    }
  },
  created () {
    this.init()
  },
  methods: {
    checkSelectable ({ status }) {
      return status === 1
    },
    init () {
      this.phone = localStorage.getItem('phone')
      this.taskList = []
      this.currentTaskDetailIndex = 0
      this.currentTaskDetail = {}
      this.pageNum = 1
      this.taskStatus = '1,3,5'
      this.taskType = 0
      this.initData()
    },
    initData () {
      this.isLoading = true
      let taskNumParams = {}
      taskNumParams[this.reqKey] = this.phone
      Promise.all([reqTaskNum(taskNumParams), reqTaskList(this.reqParams)]).then(([taskNum, task]) => {

        this.taskList = task.data.taskList
        if (task.data.taskList && task.data.taskList.length > 0) {
          this.taskList = this.handleTaskList(task.data.taskList)

          // this.currentTaskDetail = this.taskList[this.currentTaskDetailIndex]
          this.menuList[this.currentMenuIndex].num = task.data.taskCount
        }

        this.menuList.forEach(menu => {
          menu.num = taskNum.data[menu.name]
        })
        this.isLoading = false
      })
    },
    loadMoreData () {
      this.isLoadingMore = true
      if (this.menuList[this.currentMenuIndex].num > this.taskList.length) {
        this.pageNum++
        reqTaskList(this.reqParams).then(res => {
          this.taskList = this.taskList.concat(this.handleTaskList(res.data.taskList))
          // this.currentTaskDetail = this.taskList[this.currentTaskDetailIndex]
          this.menuList[this.currentMenuIndex].num = res.data.taskCount
          this.isLoadingMore = false
        })
      } else {
        // this.$message('没有更多任务')
      }
    },
    changeTaskType () {
      this.initData()
    },
    changeTaskStatus (status, index) {
      this.currentMenuIndex = index
      this.taskStatus = status
      this.initData()
    },
    handleTaskList (data) {
      if (data.length === 0) return

      data.forEach(task => {
        this.handleSingleTask(task)
      })
      return data
    },
    handleSingleTask (task) {
      // task.create_time = timestampToTime(task.create_time)
      task.finish_time = timestampToTime(task.finish_time)
      task.start_time = timestampToTime(task.start_time)
      task.end_time = timestampToTime(task.end_time)
      let { label, img } = this.taskTypeOptions.find(item => item.value === task.task_type)
      task.task_type_img = img
      task.task_type_label = label
      task.task_status_label = this.taskStatusList[task.task_status - 1]

      task.customerNameList = ''
      if (task.kehu && task.kehu.length > 0) {
        task.kehu.forEach((item, index) => {
          item.finish_time = timestampToTime(item.finish_time)
          task.customerNameList += item.customer_name
          if (index !== task.kehu.length - 1) {
            task.customerNameList += '、'
          }
        })
      }
      if (task.proveList && task.proveList.length > 0) {
        task.proveList.forEach(item => {
          item.finishTime = timestampToTime(item.finishTime)
        })
      }
    },
    showTaskDetail (task, index) {
      this.isShowTaskDetail = true
      this.currentTaskDetailIndex = index
      // this.currentTaskDetail = task
      this.getTaskDetail(task.id)
      // let obj = {}
      // if (task.kehu && task.kehu.length === 0) {
      //   obj.proveName = '本人',
      //     obj.process = this.handleTaskFinishNum(task) + '/1'
      //   obj.isFinish = task.task_status === 2 || task.task_status === 4
      //   obj.finishTime = task.finish_time
      // } else if (task.kehu && task.kehu.length > 0) {
      //   obj.proveName = task.kehu[0].prove_name
      //   obj.process = this.handleTaskFinishNum(task.kehu) + '/' + task.kehu.length
      //   obj.isFinish = task.task_status === 2 || task.task_status === 4
      //   obj.finishTime = task.finish_time
      // }

      // this.currentTaskDetail.proveList = [obj]
    },
    getTaskDetail (id) {
      let params = {
        id,
        number: this.phone,
        number_type: this.isTaskPublish ? 1 : 2
      }
      reqTaskDetail(params).then(({ data }) => {
        // data.finish_tiem = timestampToTime(data.finish_time)
        this.currentTaskDetail = data
        this.handleSingleTask(this.currentTaskDetail)
      })
    },
    closeTaskDetail () {
      this.isShowTaskDetail = false
    },
    setTaskStatusColor (value) {
      let color = 'grey'
      switch (value) {
        case 1: color = 'blue' //进行中
          break
        case 2: color = 'green'//完成
          break
        case 3: color = 'red' //逾期
          break
        case 4: color = 'grey' //逾期完成
          break
        case 5: color = 'yellow' //待开始
          break
        default: break
      }
      return color
    },
    handleTaskFinishNum (tasks) {
      let num = 0
      if (tasks instanceof Array) {
        tasks.forEach(task => {
          if (task.status === 2) ++num
        })
      } else if (tasks instanceof Object) {
        if (tasks.task_status === 2) ++num
      }

      return num
    },
    showDeleteTaskPopup (id, status) {
      this.$confirm('确认删除此任务？', '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'error',
        cancelButtonClass: 'btn-cancel',
        confirmButtonClass: 'btn-error'
      }).then(() => {
        reqDeleteTask({ id }).then(({ code, msg }) => {
          if (code === 200) {
            this.taskList.splice(this.currentTaskDetailIndex, 1)
            let menu = this.menuList[this.currentMenuIndex]
            menu.num--
            if (menu.title.indexOf('任务') !== -1) {
              let obj = this.menuList.find(({ value }) => value === String(status))
              obj.num--
            }
            this.$message.success('删除成功')
          } else {
            this.message.error(msg)
          }
          this.isShowTaskDetail = false

        })
      }).catch(() => {
        this.$message.error('删除失败')
      })
    },
    showAddTaskPopup (taskType) {
      this.currentTaskType = taskType
      this.isShowAddTaskPopup = true
    },
    addTask () {
      this.isShowAddTaskPopup = false
      this.initData()
    },
    selectAllTask (selection) {
      this.selectTaskList = selection
    },
    selectTask (selection, row) {
      if (row.status === 2) {
        alert('取消勾选')
        this.$refs.subtaskTable.toggleRowSelection(row, true)
        return
      }
      let index = this.selectTaskList.findIndex(item => item.id === row.id)
      if (index === -1) {
        this.selectTaskList.push(row)
      } else {
        this.selectTaskList.splice(index, 1)
      }
    },
    finishTask () {
      if (this.selectTaskList.length === 0) {
        this.$message.warning('请在客户维度中勾选已经完成的任务')
        return
      }
      this.$confirm('确认已经完成任务？', '提示', {
        confirmButtonText: '完成',
        cancelButtonText: '取消',
        type: 'success',
        cancelButtonClass: 'btn-cancel',
        confirmButtonClass: 'btn-success'
      }).then(() => {
        let taskIds = this.selectTaskList.map(({ id }) => id).join(',')
        reqFinishSubtask({ id: taskIds, task_id: this.currentTaskDetail.id }).then(res => {
          if (res.code !== 200) return
          // let now = timestampToTime(new Date().getTime() / 1000)
          let taskDetail = this.currentTaskDetail

          // reqFinishTask(this.currentTaskDetail.id).then(() => {
          //     this.taskList[this.currentTaskDetailIndex].finish_time = now
          //     // 这里还需要 修改主任务的状态
          //    let endTime = this.taskList[this.currentTaskDetailIndex].end_time

          // })
          // let pendingNum = taskDetail.kehu.map(({ status }) => status === 1).length
          // let finishNum = this.selectTaskList.length
          // if (pendingNum === finishNum) {
          // reqFinishTask({ id: taskDetail.id }).then(res => {
          //   if (res.code !== 200) return
          let params = {
            id: taskDetail.id,
            number: this.phone,
            number_type: this.isTaskPublish ? 1 : 2
          }
          reqTaskDetail(params).then(({ data }) => {
            this.currentTaskDetail = data
            this.handleSingleTask(this.currentTaskDetail)
            // this.showTaskDetail(this.currentTaskDetail, this.currentTaskDetailIndex)
            this.initData()
          })
          // })
          // }
          // taskDetail.kehu.forEach(item => {
          //   let index = this.selectTaskList.findIndex(task => task.id === item.id)
          //   if (index !== -1) {
          //     item.finish_time = now
          //     item.status = 2
          //   }
          // })

          // taskDetail.proveList[0].process = this.handleTaskFinishNum(taskDetail.kehu) + '/' + taskDetail.kehu.length
          // this.taskList[this.currentTaskDetailIndex].kehu = taskDetail.kehu
        })
      })
    }
  },
  watch: {
    $route () {
      this.init()
    }
  }
}
</script>

<style scoped>
.customer_name_box {
  max-width: 1000px;
  display: inline-block;
}
.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}
.task {
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
}
.container {
  width: 100%;
  height: calc(100% - 48px);
  margin-top: 20px;
  display: flex;
}
.left-nav {
  flex: 1;
  height: 100%;
  text-align: center;
  border-right: 1px solid #e6e7eb;
}
.add-task {
  padding: 10px;
  padding-top: 20px;
  border-bottom: 1px solid #e6e7eb;
}
.options-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.option {
  width: 80px;
  text-align: center;
  cursor: pointer;
  padding: 8px;
  line-height: 14px;
}
.option:hover {
  background: #f5f5f7;
  border-radius: 4px;
}
.btn-add-task {
  background: #5757d9;
  border: 1px solid #5757d9;
  color: #ffffff;
}
.el-menu {
  border-right: none;
}

.el-menu-item.is-active {
  color: #5757d9;
  background: #e5ebfb;
  border-right: 4px solid #5757d9;
}

.right-content {
  flex: 10;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.select-task-type {
  width: 160px;
}
.select-task-type >>> .el-input__inner:hover,
.select-task-type >>> .el-input__inner:focus {
  border: 1px solid #5757d9;
}
.el-select-dropdown__item.selected {
  color: #5757d9;
}

.task_container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 4px;
  background-color: #f5f5f7;
  margin-top: 20px;
  overflow: auto;
}
.empty_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.task_container .item_task {
  width: 100%;
  min-height: 120px;
  background: #ffffff;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
}
.task_container .item_task:hover {
  box-shadow: 0 8px 20px -8px #00000020;
}
.task_container .item_task:nth-child(n + 2) {
  margin-top: 15px;
}
.task_container .item_task .item_right {
  width: 0;
  flex: 1;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.item_task .task-info {
  margin-top: 8px;
  font-size: 14px;
  color: #22222280;
  line-height: 22px;
  display: flex;
  align-items: center;
}
.item_task .task-content {
  max-width: 90%;
  font-size: 14px;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

i {
  margin-right: 5px;
}
.mr15 {
  margin-right: 15px;
}

.mr20 {
  margin-right: 20px;
}
.flex-v-center {
  display: flex;
  align-items: center;
}

.task-detail-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn-task-detail {
  display: inline-block;
  padding: 5px 15px;
  border: 1px solid #e1e3e6;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
}
.btn-task-detail:active,
.btn-task-detail:hover,
.btn-task-detail:focus {
  color: #5757d9;
  border-color: #5757d9;
}
.btn-task-detail:nth-child(n + 2) {
  margin-left: 10px;
}

.task_detail_title {
  font-size: 18px;
  color: #222;
  font-weight: 500;
}
.task_detail_status {
  font-weight: 500;
  font-size: 14px;
}
.grey {
  color: rgb(153, 153, 153);
}
.red {
  color: rgb(242, 73, 87);
}
.bg-red {
  background: rgb(242, 73, 87);
}
.green {
  color: rgb(19, 191, 134);
}
.bg-green {
  background: rgb(19, 191, 134);
}
.yellow {
  color: rgb(242, 121, 0);
}
.blue {
  color: rgb(24, 133, 242);
}
.task_detail_info {
  box-sizing: border-box;
  padding: 20px;
  border-top: 10px solid #f0f0f2;
}
.task_detail_info_line {
  font-size: 14px;
  color: #222;
  line-height: 22px;
}
.task_detail_info_line:nth-child(n + 2) {
  margin-top: 15px;
}
.task_detail_info_line > span:first-child {
  opacity: 0.5;
  width: 80px;
}
.task_detail_info_line > span:nth-child(2) {
  display: inline-block;
  overflow-wrap: break-word;
  min-width: 0;
}

.task_detail_content {
  box-sizing: border-box;
  padding: 20px;
  border-top: 10px solid #f0f0f2;
}
.task_detail_content >>> .el-tabs__item:hover {
  color: #5757d9;
}
.task_detail_content >>> .el-tabs__item.is-active {
  color: #5757d9;
}

.task-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}
</style>